.alertListTile .tile-icon-container-left {
    width: 60px !important;
}
@media only screen and (min-width: 1024px) {
    .alertListTile {
        width: 65%;
        margin: 0px auto;
    }
    .notificationStoreTile {
        width: 68%;
        margin: 0px auto;
    }
    .noALertClass {
        width: 68%;
        margin: 0px auto;
    }
}