.edit-accessory-wrapper {
  font-family: "Poppins", sans-serif;
}
.edit-accessory-nav {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.edit-accessory-header span {
  font-size: 27px !important;
  margin-bottom: 10px;
}
.edit-accessory-header {
  margin-bottom: 10px;
}
.edit-accessory-header-2{
  margin-top: 10px;
}
.edit-accessory-subContent {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}
.edit-input-label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.edit-input-label-editSystem {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.edit-accessory-bodyWrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  margin-top: 20px;
}
.edit-accessory-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width:720px;
  position: relative;
  /* padding: 20px 20px 20px 20px; */
}
.edit-accessory-button {
  width: 100%;
  max-width:720px;
  position: relative;
  bottom: 80px;
  margin-top: 20px;
  padding: 0px 20px 20px 20px;
  justify-items: center;
}
.navigate {
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  margin-left: 18px;
}

.edit-accessory-btn-wrapper {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  max-width: 720px;
  position: absolute;
  padding-bottom: 2rem;
  /* padding: 0px 20px; */
}

.edit-accessory-savebtn {
  display: flex;
  justify-content: center;
}
.edit-accessory-savebtn div {
  /* display: flex;
    justify-content: center; */
  width: 100%;
  max-width: 520px;
}
.edit-accessory-savebtn div button {
  border-radius: 10px;
  /* margin-top: 25px; */
  max-width: 520px;
  /* padding: 11px 0px; */
}
.edit-accessory-removebtn {
  display: flex;
  justify-content: center;
}
.edit-accessory-removebtn div {
  width: 100%;
  max-width: 520px;
}
.edit-accessory-removebtn div button {
  color: #9A9A9A !important;
  border-color: #9A9A9A !important; 
  margin-top: 20px;
  border-radius: 10px;
  max-width: 520px;
  /* padding: 11px 0px; */
}

.edit-accessory-date-picker {
  position: relative;
  background: #ffffff;
  border: 1px solid #bfc1c4;
  border-radius: 10px;
  height: 46px;
  /* padding: 0px 0px 0px 20px; */
}
.edit-accessory-date-picker .react-datepicker-wrapper,
.edit-accessory-date-picker .react-datepicker__input-container {
  height: 100%;
}
.edit-accessory-date-picker-EditSystem {
  position: relative;
  background: #ffffff;
  border: 1px solid #bfc1c4;
  border-radius: 10px;
  height: 46px;
  /* padding: 0px 0px 0px 20px; */
}
.edit-accessory-date-picker-EditSystem .react-datepicker-wrapper,
.edit-accessory-date-picker-EditSystem .react-datepicker__input-container {
  height: 100%;
}
.edit-accessory-date {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  padding: 0px 0px 0px 20px;
}
.edit-accessory-pname {
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 18px;
}
.edit-accessory-slider-wrapper {
  margin-top: 40px;
}
.edit-accessory-slider {
  margin-top: 20px;
}
.edit-accessory-editSliderLabel{
  font-size: 16px;
  font-weight: 600;
}
.adjustReplacementText{
  font-size: 13px;
  font-weight: 400;
  margin-top: 20px;
}
.email-icon2{
  position: absolute;
  top: 15px;
  right: 12px;
  z-index: 1;
}
.PageCount {
  padding-left: 22px;
  padding-top: 15px;
  font-family: "poppins";
  font-size: 18px
}
@media only screen and (min-width: 380px) {
  .edit-accessory-btn-wrapper {
    position: relative;
    bottom: 10px;
    left: 0;
    /* padding-bottom: 2rem; */
  }
}
@media only screen and (max-width: 380px) {
  .edit-accessory-btn-wrapper {
    position: relative;
    /* bottom: 10px; */
    left: 0;
    /* padding-bottom: 2rem; */
  }
}
/* 
@media only screen and (min-width: 320px) {
  .edit-accessory-btn-wrapper {
    position: absolute;
    left: 0;
  }
} */
@media only screen and (min-width: 760px) {
  .edit-accessory-button {
    margin-left: auto; 
    margin-right: auto; 
    max-width: 720px;
    width: 35%;
    margin-top: 120px;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 30px;
  }
  .edit-accessory-body {
    min-height: 0px;
    width: 50%;
    max-width:720px;
    position: relative;
  }
} 
.edit-accessory-slider .ant-input-number-handler-wrap {
  display: none !important;
}