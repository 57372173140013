.notification-screen{
    background-image: url('../common_features/assets/triangle.png');
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: bottom right;    
}
.notification-image{
    text-align: left;
    margin-top: 26px !important;
    margin-bottom: 16px !important;
}
.onboard-notification-image{
    margin-top: 80px !important;
}
.notification-screen-title{
    text-align: left;
    /* margin-top: 40px !important; */
    margin-bottom: 16px !important;
}
.notification-screen-detail{
    margin-bottom: 16px;
}
.notification-wrapper{
    max-width: 480px !important;
    margin: 0 auto 0 !important;
}
.notification-container{
    margin-top: 64px !important;
}
.accept-button-container{
    max-width: 100% !important;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    padding: 40px 40px;
}
.accept-button-wrapper{
    max-width:480px;
    margin: auto;
}
.sms-notification-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #EBEBEB;
    margin-bottom: 30px;

}
.sms-notification-container{
    /* margin-bottom: 30px; */
    display: flex;
    flex-direction: column;
    /* border-bottom: 2px solid #EBEBEB; */
    padding-bottom: 12px;
}
.switch-button-sms{
    margin-top: 10px;
}
.switch-button-sms2{
    margin-top: 0px;
}
.notification-tips-wrapper{
    max-width: 478px;
    display: flex;
    margin-bottom: 53px;
}
.email-notification-container{
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 12px;
}

.phone-input1{
    border-color:#BFC1C4 !important;
}
.phone-input-disabled{
    background-color: #f5f5f5 !important ;
}

.phone-input2{
    border-color:#E00932 !important;
}
.input-warning{
    position: absolute;
    top: 38px;
    right: 12px;
    z-index: 1;
  }
.edit-text-content {
    font-weight: 700;
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    color: #0b3471;
    margin-left: 18px;
}

.back-navigation-content{
    display: flex;
    margin-top: 18px;
}

/* tablet */
@media only screen and (max-device-width: 1024px) {
    .notification-wrapper{
        margin: 10px auto 0 !important;
    }
    .notifcation-screen-detail{
        margin-bottom: 10px;
    }
    .accept-button-container{
        max-width: 100% !important;
        background-color: #fff !important;
    }
}
@media only screen and (min-device-width: 1024px) {
    .back-navigation-content{
        display: none;
    }
}



@media only screen and (max-device-width: 767px) {
    .notification-screen-title{
        text-align: left;
    }
    .notification-screen{
        background-size: 95%;
        /* background-image: url('../release/assets/downSideTriangle.png'); */
        background-repeat: no-repeat;
        background-position:right top;    
        
    }
    .accept-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        position: relative;
    }
    .notification-wrapper{
        max-width: 100% !important;
        margin: auto !important;
    }
    .back-navigation-content{
        display: flex;
        margin-top: 18px;
    }
}
@media only screen and (max-device-width: 320px) {
    .notification-screen-title{
        margin-top: 0px !important;
    }
}