/* .your-need-modal-wrapper {}
.water-going-modal-wrapper .ant-modal-root .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body  {
    background-color: red !important;

}


.ant-modal-root.ant-modal-wrap.ant-modal.ant-modal-content.ant-modal-body.cust-modal-header-icon {
    display: none !important;
    background: red !important;
}


.parent div .child{

}
 */

/* .ant-modal-root
  .ant-modal-wrap
  .ant-modal.water-going-modal
  .ant-modal-content
  .ant-modal-body {
  background-color: red !important;
} */
/* .ant-modal-root .ant-modal-wrap .ant-modal .ant-modal-content .ant-modal-body {
  margin-top: 100px !important;
}


} */
/* 
.water-going-modal-wrapper{
    overflow-y: visible !important;
    background: chartreuse;

} */
.what-your-need-modal-top-content{
  font-weight: 600;
  font-family: "Poppins", sans-serif;
font-size: 16px;
margin-bottom: 10px;
}
@media only screen and (max-device-width: 620px) {
  .water-going-modal .ant-modal-content .ant-modal-body {
    height: 70vh;
    overflow-x: auto;
    overflow-y: scroll;
  }
}

.what-you-need-wrapper {
  text-align: left;
}
.what-you-need-wrapper span {
  /* background: red; */
  text-align: left;
  width: 100%;
}

.what-you-need-wrapper-images {
}

.success-component-wrapper .success-component-top-content {
  font-weight: 600 !important;
  color: #4f8309 !important;
  font-size: 16px;
  margin-top: 12px !important;
  margin-bottom: 16px !important;
}
.success-component-wrapper .success-component-content {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.success-component-wrapper .success-component-sub-heading {
  text-align: center !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  margin-top: 0.5rem;
}

/*   ABOUT THIS SYSTEM MODAL COMPONENT**/
.ats-wrapper {
  text-align: left;
  /* padding: 20px; */
  font-family: "Poppins", sans-serif;
}
.ats-heading {
  font-weight: 600;
  font-size: 18px;
}
.ats-subheading {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 38px;
}
.ats-content {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 30px;
}
.ats-websittlink {
  font-size: 13px;
  font-weight: 700;
  color: #006e96;
  margin-bottom: 10px;
}

.ats-websittlink>a {
  font-size: 13px;
  font-weight: 700;
  color: #006e96;
  margin-bottom: 10px;
  text-decoration: none;
}
.learnMoreText{
  color: #006E96;
  font-size: 13px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
margin-bottom: 30px;
}
