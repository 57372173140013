.edit-sensor-wrapper {
  /* display: flex;
  justify-content: center; */
  font-family: "Poppins", sans-serif;
  /* margin: 25px 25px 25px 25px; */
}
.edit-sensor-body {
  min-height: calc(100vh - 147px);
  width: 100%;
  max-width: 1024px;
  position: relative;
  padding: 20px 20px 20px 20px;

}
.edit-detail-dashboard-navigate {
  font-family: "Poppins", sans-serif;
  display: flex;
  margin-top: 10px;
}
.left-arrow {
  height: 21px;
  width: 12px;
}
.navigate {
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  margin-left: 18px;
}
.edit-sensor-text-wrapper {
  /* margin-top: 20px; */
}
.edit-sensorr-text {
  font-size: 27px;
  color: #0b3471;
  font-weight: 600;
}
.sub-text-wrapper {
  margin-top: 10px;
}
.sub-text {
  font-size: 16px;
  color: #333333;
  font-weight: 400;
  margin-top: 15px;
}
/* .date-input-wrapper{
      max-width: 520px;
    } */
.installation-date-text {
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.image-wrapper {
  /* max-width: 520px; */
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.move-remove-wrapper {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  position: absolute;
  margin-bottom: 2rem;
  padding: 0px 20px;
}
.move-sensor-button {
    display: flex;
  justify-content: center;
}
.move-sensor-button div{
  /* display: flex;
  justify-content: center; */
  width: 100%;
  max-width: 520px;

}
.move-sensor-button div button {
  border-radius: 10px;
  /* margin-top: 25px; */
  max-width: 520px;
  /* padding: 11px 0px; */
}
.remove-sensor-button {
  display: flex;
  justify-content: center;
}
.remove-sensor-button div {
  width: 100%;
  max-width: 520px;
}
.remove-sensor-button div button {
  color: #e00932 !important;
  border-color: #e00932 !important;
  margin-top: 20px;
  border-radius: 10px;
  max-width: 520px;
  /* padding: 11px 0px; */
}
.remove-sensor-button div button:disabled {
  color: #9A9A9A !important;
  border-color: #9A9A9A !important;

  /* padding: 11px 0px; */
}
.edit-sensor-pname{
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 18px;
}

@media only screen and (min-width: 768px) {
  .edit-sensor-body {
    width: 100%;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1024px) {
  .edit-sensor-body {
    width: 100%;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 520px) {
  .move-remove-wrapper {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}
@media only screen and (min-width: 320px) {
  .move-remove-wrapper {
    position: absolute;
    /* bottom: 0px; */
    left: 0;
    padding-bottom: 20px;
  }
}