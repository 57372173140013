.repalce-filter-wrapper {
    /* padding: 0px 20px 20px 20px; */
    font-family: "Poppins", sans-serif;
  
}
.replace-filter-body-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 20px 20px;

}
.replace-filter-body {
    position: relative;
    min-height: calc(100vh - 187px);
    width: 100%;
    max-width: 720px;
}
.replace-filter-heading {
  margin-bottom: 10px;
}
.replace-filter-heading span {
  font-size: 27px !important;
}
.relace-filter-content {
  margin-bottom: 33px;
  font-size: 16px;
  font-weight: 400;
}
.replace-filter-label {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}
.replace-filter-btn {
    position: absolute;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    width: 100%;
    padding-bottom: 2.5rem;
}
.replace-filter-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.replace-filter-btn div button {
  max-width: 520px;
  /* padding: 11px 0px; */
}

.replace-filter-date-picker {
  position: relative;
  background: #ffffff;
  border: 1px solid #bfc1c4;
  border-radius: 10px;
  height: 46px;
  /* padding: 0px 0px 0px 20px; */
 
}
.replace-filter-date-picker .react-datepicker-wrapper,.replace-filter-date-picker  .react-datepicker__input-container{
  height: 100%;
}
.replace-filter-date{
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  padding: 0px 0px 0px 20px;
}
.email-icon{
  position: absolute;
  top: 15px;
  right: 12px;
  z-index: 1;
}
@media only screen and (max-width: 320px) {
  .replace-filter-btn{
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  .relace-filter-content {
    margin-bottom: 6px;
  }
}
@media only screen and (max-width: 520px) {
  .replace-filter-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  .replace-filter-heading span {
    font-size: 32px !important;
  }
}

@media only screen and (min-width: 520px) {
  .replace-filter-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  .replace-filter-heading span {
    font-size: 32px !important;
  }
}

@media only screen and (min-width: 760px) {
  .relace-filter-content {
    margin-bottom: 80px;
  }
}

@media only screen and (max-height: 470px) {
  .replace-filter-btn {
    margin-top: 80px;
    position: relative !important;
  }
}

