.container-style {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom:20px;
}
.img_not_added{
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0px;
color: #9A9A9A;
margin: 25px;
}

.date-picker-mobile-from{
  font-size: 16px;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  padding: 14px 0px 0px 20px;
}

.updated_by_val{
  display: contents;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  align-self: center;
  margin-top: 15px;
  color:#9A9A9A
}
.updated_by{
  margin-left:25px; 
  margin-top: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.notes_not_added{
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #9A9A9A;
  margin: 10px;
  }
.details-div {
  flex:4;
  display: flex;
  align-items: baseline;
  justify-content: end;
}
.part-number{
  text-align: right;
}
.model-name{
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0px;
}
.carousel .ant-modal-body,
.ant-modal-footer {
  padding: 0px;
  width: 0px;
  height: 0px;
}

.water_details {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  margin-top: -20px;
  padding-left: 5px;
  padding-right: 10px;
  max-width: 100px;
  overflow-x: auto;
  letter-spacing: 0px;
  text-align: right;

}

.details_present {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: right;
}

.water_details_empty {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #E00932;
  text-align: right;
}

.notes-container {
  max-width: 330px;
  min-height: Hug (121px);
  border-radius: 10px;
  margin: 23px;
  padding: 10px;
  bottom: 30px;
  background-color: #F4F4F4;
  box-shadow: 0px 2px 0px 0px #EBEBEB;

}

.filter .ant-modal-content{
  border-radius: 12px 12px 0px 0px !important;
}
.filter .ant-modal-body {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  height: 80vh;
    overflow-x: auto;
    overflow-y: scroll;
}

.filterTitle-div-style{
  display:flex;
  justify-content: space-between;
}
.buttons-div-style{
  display:flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
}
.button-style{
flex:1;
padding:10px;
}
.filter-label-div{
  margin-left: 20px;
}
.filter-cross-div{
  margin-right: 20px;
  margin-top: 20px;
}
.filter .cust-cross-icon{
margin-top: -70px;
}

.datepicker-container{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}
.checkboxes-container{
  padding-left: 20px;
  padding-right: 20px;
}
.checkbox-container{
  padding-left: 0px;
}

.filter-text{
  font-size: 13px;
  color: #006e96;
  font-weight: 700;
  margin-left: 5px;
  align-self: center;
}

.label-style-service-logs {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.select-services-label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.filters-modal-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.chip-container-label{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}
.service-log-date-picker-mobile {
  position: relative;
  background: #ffffff;
  border: 1px solid #bfc1c4;
  border-radius: 10px;
  height: 46px;
}
.calendar-icon{
  width: 20px;
  height: 22px;
  margin-left: 10px;
}
.date-picker-web-from{
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  padding: 0px 0px 0px 15px;
}
.service-logs-date-picker {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #bfc1c4;
  border-radius: 10px;
  align-items: center;
}
.datepickers-container-div{
  display: flex;
  justify-content: space-around;
  margin: 0px 10px 20px 10px;
}
.calendar-icon-mobile{
  position: absolute;
  top: 15px;
  right: 12px;
  z-index: 1;
}
.breakLine {
  background: #D3D3D3;
  height: 1px;
}
.chips-container{
  margin: 20px;
}
.fromto-datepicker-title-web{
font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 20px;
}



.notes-input {
  margin-left: 10px;
  padding: 15px;
  width: 365px;
  height: Hug (96px);
  padding: 12px, 20px, 12px, 20px;
}

.edit_pencil {
  margin-top: 15px;
  cursor: pointer;
}
.edit-area{
  margin-right:10px;
  padding-right:20px;
}
.edit_text {
  margin-left: 20px;
  padding-right:20px;
  margin-top: -15px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  color: #006E96;
  cursor: pointer;

}

.image-list {
  width: 99px;
  height: 73px;
  margin-top: 25px;
  margin-right: 5px;
  border-radius: 7px;
  border: 2px;
  border: 2px solid #FFFFFF;
}

.caption-container {
  height: 60px;
  width:294px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #F4F4F4;
}

.caption-style {
  font-family: Poppins;
  margin-left: 12px;
  margin-bottom: -20px;
  align-items: center;
  align-self: center;
  font-size: 13px;
  padding-top: 10px;
  font-weight: 400;
  max-height: 50px;
  overflow-wrap: inherit;
}

.system-detail-value {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;

}



.dots-container {
  display: flex;
  margin: 0 6px;
  height: auto;
  margin-right: 23px;
}

.dots-container::before {
  content: '';
  margin: 0 6px;
  min-height: 100%;
  width: 1px;
  position: absolute;
  margin-left: 30px;
  transform: translateX(-50%);
  border: 1px dotted #008ABC;
}
.log-container{
  max-height:350px
}
.timeline-text {
  font-family: Poppins;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 60px;
  margin-right: 10px;
  margin-bottom: 30px;
  margin-top: 20px;
}


.timeline-date {
  font-family: Poppins;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
  line-height: 18px;
  margin: 0 60px;
  margin-top: -15px;
  margin-right: 23px;


} 
.timeline-container {
  display: flex;
  overflow:auto;
  height:500px;
  margin:10px;
  flex-direction: column;
}

.timeline-container  .dots-container :before {
  display: none;
}

.timeline-container  .dots-container :after {
  background: #008ABC;
}
.timeline_dot {
  width: 12px;
  height: 12px;
  margin-top: 10px;
  margin-left: 30px;
  border-radius: 50%;
  border: 6px solid #008ABC;

}

.system-detail {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;

}

.notes-text {
  margin: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  overflow: overlay;
}

.image-container {
  max-width: 330px;
  height: 253px;
  border-radius: 10px;
  border: 1px solid #EBEBEB;
  margin: 23px;
  padding: 10px;
  box-shadow: 0px 2px 0px 0px #EBEBEB;

}
.more-images{
  width: 120px;
  height: 101px;
  margin: 8px;
  border-radius: 7px;
}
.image-count {
  width: 120px;
  height: 101px;
  border-radius: 10px;
  border: 2px solid #006E96;
  margin-left: 135px;
  margin-top: -115px;
  box-shadow: 0px 2px 0px 0px #EBEBEB;
  cursor: pointer;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #006E96;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;

}

.image-area {
  width: 120px;
  height: 101px;
  margin: 8px;
  border-radius: 7px;
  border: 1px solid #EBEBEB;
}

.list-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #EBEBEB;
  margin-bottom: 20px;
  margin-right: 50px;
  line-height: "22px",

}

.dropdown-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 15px;
}

.dropdown-container {
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #EBEBEB;
  margin-top: 30px;
  margin-bottom: 2px;
}

.description-text {
  width: 100%;
  margin-left: 25px;
  margin-bottom: 15px;
}

.dropdown-arrow {
  transform: translateY(-50%);
  margin-right: 25px;
  margin-top: 10px;
  cursor: pointer;
}

.load-more {
  margin-left: 18px;
  margin-right: 18px;
  position: 'absolute';
  bottom: '0px';
  left: '50%';
  right: 0;
  min-width: '335px';
  max-width: '860px';
  transform: 'translateX(-50%)'
}

.system-name {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-top: 9px;
  margin-left: 5px;
}

.middle-text-service-log {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #BFC1C4;
  margin-bottom: 1px;
  margin-right: 23px;
  padding:15px;
  margin-left: 10px
}

.timeline {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.timeline-border {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #BFC1C4;
  margin-right: 50px;
  margin-left: 50px;
  margin-top: -20px;
  margin-bottom: 20px;

}

.date {
  width: 264px;
  height: 29px;
  top: 302px;
  left: 2px;
  margin-left: 25px;
}

.system-part-number {
  font-size: 13px;
  color: #333333;
  font-weight: 400;
  margin-left: 5px;
}

@media only screen and (min-width: 520px) {

  .system-name {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
  }

  .system-part-number {
    font-size: 16px;
  }

}

@media only screen and (max-width: 320px) {

  .system-name {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
  }

  .system-part-number {
    font-size: 13px;
  }

}

@media only screen and (min-width: 1024px) {
  .system-name {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
  }

  .system-part-number {
    font-size: 16px;
  }

}

@media only screen and (max-width: 380px) {
  .image-area {
    width: 85px;
    height: 85px;
    margin: 8px;
    border-radius: 7px;
    border: 1px solid #EBEBEB;
  }
}

@media only screen and (min-width: 720px) {
  .select-services-label {
      margin-left: 20px;
    }
  
    .checkbox-container {
      padding-left: 20px;
    }
  
    .checkboxes-container {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 20px;
    }
  
    .filter .ant-modal-content {
      border-radius: 0px 0px 0px 0px !important;
    }
    .chips-container-parent-div {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  
    .filter .ant-modal-body {
      padding: 0px !important;
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow-x: auto;
      overflow-y: scroll;
    }
  
    .filter {
      position: absolute;
      left: auto;
      right: 0;
    }
  .container-style {
    margin: 0px auto;
    width: 50%;
  }
  .main-div ::-webkit-scrollbar  {
    display: none;
  }
  .carousel .cust-cross-icon{
    margin-top: -310px;
    right: -120px;
  }
  .caption-container{
    width:740px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #F4F4F4;
  }
  .caption-container ::-webkit-scrollbar {
    height: 180px;
    display:none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #F4F4F4;

  }
}