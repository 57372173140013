.system-Infomation-wrapper {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px #EBEBEB;
  border-radius: 12px;
  padding: 9px 9px 9px 0;
  font-family: "Poppins", sans-serif;
  flex-direction: column;
  margin: 26px 0px 35px 0px;
  margin: 23px;
}
.arrow-content-container {
  display: flex;
  padding: 0px 9px 9px 0;
  margin: 12px 0px 0px 8px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.edit-water-photos-text{
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 22px;
}
.edit-system-icon{
  margin-left: 7px;
  width: 13px;
  height: 13px;
}
.edit-system-text{
  font-size: 13px;
  color: #006e96;
  font-weight: 700;
  margin-left: 5px;
}
.outer-div-edit-text{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.system-left-right-content-container {
  display: flex;
}

.system-left-content-container {
  margin-top: 5px;
  margin-left: 6px;
}

.right-content-container {
  flex: 1;
  flex-direction: column;
  width: 50%;
  /* margin-left: 10px; */
}

.installed-date-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.system-image {
  height: 10vh;
  width: 80px;
  padding: 0px 10px 10px 10px;
  object-fit: contain;
  margin-left: 10px;
}
.lower-content {
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 7px;
  cursor: pointer;
}
.system-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.system-text-content {
  display: flex;
  flex-direction: column;
}

.middle-text-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #bfc1c4;
  margin-bottom: 13px;
  margin-right: 3px;
  padding-bottom: 20px;
}
.middle-text-content-style{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #bfc1c4;
  margin-bottom: 13px;
  margin-right: 3px;
  padding-top: 2px;
  padding-bottom: 15px;
}
.status-content-container {
  margin-top: 10px;
}

.status-content {
  display: flex;
  align-items: center;
}

.question-image {
  width: 21px;
  height: 20px;
}

.about-text {
  margin-left: 10px;
  color: #006e96;
  font-weight: 600;
  font-size: 13px;
}

.water-destination {
  display: "flex";
  margin-top: 60px;
}

.system-name {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-top: 9px;
  margin-left: 5px;
  word-wrap: break-word;
}

.middle-text-accessory {
  margin-left: 13px;
  margin-right: 13px;
}

.middle-text-wrapper-accessory{
  margin-left: 15px;
  margin-left: 15px;
  margin-top: 0px !important;
}

.water-dest-wrapper {
  display: flex;
}

.arrow-system-tile {
  margin-top: 16px;
  margin-right: 12px;
}

.water-dest-cwrapper {
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}
.water-dest-content {
  font-size: 10px;
  padding: 2.5px;
}

.water-dest-content p {
  margin: 0;
}

.water-dest-image {
  height: 25px;
  width: 22px;
}

.system-part-number {
  font-size: 13px;
  color: #333333;
  font-weight: 400;
  margin-left: 5px;
}

.installed-text {
  font-size: 13px;
  color: #333333;
  font-weight: 400;
}

.date-text {
  font-size: 13px;
  color: #333333;
  font-weight: 400;
  margin-left: 5px;
}


@media only screen and (min-width: 520px) {
  .system-Infomation-wrapper {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px #EBEBEB;
    border-radius: 12px;
    padding: 20px;
    font-family: "Poppins", sans-serif;
    flex-direction: column;
    margin: 26px 0px 35px 0px;
    margin: 23px;
  }
  .installed-date-wrapper{
    display: flex;
  }

  .edit-system-text{
    font-size: 16px;
    margin-left: 10px;
  }
  .right-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
  }
  .system-image {
    height:10vh;
    width: 80px;
    padding: 0px 10px 10px 10px;
    object-fit: contain;
  }
  .system-name {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
  }

  .system-part-number {
    font-size: 16px;
  }
  .water-dest-image {
    height: 30px;
    width: 30px;
  }
  .date-text {
    font-size: 16px;
  }
  .installed-text {
    font-size: 16px;
  }
  .water-dest-wrapper {
    display: flex;
    margin-top: 34px;
  }
  .water-dest-content {
    font-size: 13px;
    padding: 2.5px;
  }
  .lower-content {
    margin-left: 21px;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  .question-image {
    width: 17px;
    height: 17px;
    margin-left: 10px;
  }
  .about-text {
    margin-left: 10px;
    font-size: 15px;
  }
}

@media only screen and (min-width: 520px) {
  .water-dest-wrapper {
    display: flex;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 320px) {
  .system-Infomation-wrapper {
    padding: 5px 5px 5px 0px;
  }
  .installed-date-wrapper{
    display: flex;
  }

  .right-content-container {
    flex: 1;
    flex-direction: column;
    width: 40%;
    margin-left: 6px;
  }
  .edit-system-text{
    font-size: 10px;
    margin-left: 10px;
  }
  .system-image {
    height: 10vh;
    width: 55px;
    padding: 0px 10px 10px 10px;
    object-fit: contain;
  }
  .system-name {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
  }

  .system-part-number {
    font-size: 10px;
  }
  .water-dest-image {
    height: 20px;
    width: 20px;
  }
  .date-text {
    font-size: 10px;
  }
  .installed-text {
    font-size: 10px;
  }
  .water-dest-wrapper {
    display: flex;
    margin-top: 34px;
  }
  .water-dest-content {
    font-size: 9px;
    padding: 2.5px;
  }
  .lower-content {
    margin-left: 21px;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  .question-image {
    width: 15px;
    height: 15px;
  }
  .about-text {
    margin-left: 10px;
    font-size: 12px;
  }

}

@media only screen and (min-width: 320px){
  .right-content-container {
    flex: 1;
    flex-direction: column;
    width: 40%;
    margin-left: 6px;
  }
}

@media only screen and (min-width: 1024px) {
  .system-Infomation-wrapper {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 12px 0px #EBEBEB;
    border-radius: 12px;
    padding: 20px;
    font-family: "Poppins", sans-serif;
    flex-direction: column;
    margin: 26px 0px 35px 0px;
  }
  .installed-date-wrapper{
    display: flex;
  }

  .right-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
  }
  .edit-system-text{
    font-size: 16px;
    margin-left: 10px;
  }
  .system-image {
    height: 10vh;
    width: 80px;
    padding: 0px 10px 10px 10px;
    object-fit: contain;
  }
  .system-name {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
  }

  .system-part-number {
    font-size: 16px;
  }
  .water-dest-image {
    height: 30px;
    width: 30px;
  }
  .date-text {
    font-size: 16px;
  }
  .installed-text {
    font-size: 16px;
  }
  .water-dest-wrapper {
    display: flex;
    margin-top: 34px;
  }
  .water-dest-content {
    font-size: 13px;
    padding: 2.5px;
  }
  .lower-content {
    margin-left: 21px;
    margin-bottom: 10px;
    margin-top: 7px;
  }
  .question-image {
    width: 17px;
    height: 17px;
    margin-left: 10px;
  }
  .about-text {
    margin-left: 10px;
    font-size: 15px;
  }

}
.arrow-system-tile {
  cursor: pointer;
}