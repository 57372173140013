.sensor-placement-wrapper {
  /* padding: 0px 20px 20px 20px; */
  font-family: "Poppins", sans-serif;
}
.sensor-placement-body-wrapper {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 20px 20px;

}
.sensor-placement-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width: 720px;
  position: relative;
}

.sensor-placement-wrapper .sensor-placement-heading span {
  font-size: 27px !important;
}

.sensor-placement-btn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  /* padding: 0px 20px; */
  padding-bottom: 2.5rem;
  position: absolute;
  margin-top: 30px;
}

.sensor-placement-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sensor-placement-btn div button {
  max-width: 520px;
  /* padding: 11px 0px; */
}
.sensor-placement-subContent{
    font-weight: 400;
    margin: 20px 0px 25px 0px;
}
.sensor-placement-image-div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.selectedOptions {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: 1px solid #9A9A9A;
  outline: none;
  border-radius: 10px;
  background: #FFFFFF;
  margin-bottom: 17px;
}
.selectedOptions.selected {
  border: 1px solid #008ABC;
}
.selectedOuter {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 5px;
  background-color: transparent;
  border: 1px solid #9A9A9A;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectedOuter.selected {
  border: 1px solid #008ABC;
}
.selectedInner.show {
  background-color: #008ABC;
}
.selectedInner {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  padding: 5px;
  background-color: transparent;
}

.btn-select-content{
font-weight: 600;
font-size: 16px;
}
@media only screen and (min-width: 320px) {
    .sensor-placement-btn {
      /* bottom: 10px; */
      left: 0;
    }
  }
@media only screen and (min-width: 376px) {
  .sensor-placement-btn {
    bottom: 10px;
    left: 0;
  }
}
@media (min-width:412px) and (max-width:414px) {
  .sensor-placement-btn {
    position: absolute;
    bottom: -50px;
    left: 0;
  }
}

@media (min-width:520px) and (max-width:541px) {
  .sensor-placement-btn {
    position: absolute;
    bottom: -60px;
    left: 0;
  }
}


@media only screen and (min-width: 541px) {
  .sensor-placement-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}
