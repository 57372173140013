.product-found-wrapper {
  font-family: "Poppins", sans-serif;
}
.product-body-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
}
.product-found-body {
  position: relative;
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width:720px;
}
.product-content-heading {
}
.product-content-heading span {
  font-size: 27px !important;
}
.product-found-content {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
}
.product-found-centerImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px;
}

.product-notfound-centerImg {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 160px 0px;
  flex-direction: column;
}
.product-found-centerImg img {
  /* width: 234px; */
  height: 160px;
}

.product-notfound-centerImg img {
  height: 48px;
  width: 48px
}
.product-found-input {
  margin-bottom: 40px;
}
.product-found-input label {
  margin-bottom: 9px;
  font-size: 16px;
  font-weight: 600;
}
.product-found-conti-btn {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  padding-bottom: 2.5rem;
}
.product-found-conti-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-found-conti-btn div button {
  max-width: 520px;
}

@media (min-width: 380px) and (max-width: 759px) {
  .product-found-conti-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}
@media only screen and (max-width: 380px) {
  .product-found-conti-btn {
    position: absolute;
    left: 0;
        /* bottom: 10px; */
  }
}

@media only screen and (min-width: 760px) {
  .product-found-centerImg img {
    width: auto;
    height: auto;
  }
  .product-found-conti-btn {
    position: absolute;
    left: 0;
        /* bottom: 10px; */
  }
}
@media only screen and (max-width: 320px) {
  .product-found-conti-btn{
    position: absolute;
    left: 0;
    bottom: 0px;
    padding-bottom: 0.5rem;
  }
}
@media only screen and (max-width: 375px) {
  .product-found-conti-btn{
    position: absolute;
    left: 0;
    bottom: 0px;
    padding-bottom: 0.5rem;
  }
}

/* 
@media only screen and (min-width: 320px) {
  .product-found-conti-btn {
    position: absolute;
    left: 0;
    bottom: 10px;

  }
} */
