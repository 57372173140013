.provisioning-detail-wrapper {
 
  font-family: "Poppins", sans-serif;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}
.provisioning-body-wrapper{
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.provisioning-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width: 720px;
  position: relative;
}

.provisioning-heading{

}
.provisioning-heading span{
  font-size: 27px !important;
}
.provisioning-subContent {
  margin: 10px 0px 44px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.part-input-label {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}

.provising-device-select {
  height: 46px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 720px!important;
}

.provising-device-select .ant-select-selector {
  height: 46px!important;
  width: 100% !important;
  max-width: 720px!important;

  border-radius: 10px !important;

}
.provising-device-select .ant-select-selector span{
  width: 100% !important;
  max-width: 720px!important;
  height:100%!important;

}
.provising-device-select .ant-select-selector span.ant-select-selection-search input{ 
  width: 100% !important;
  height:100%!important;
  max-width: 720px!important;

}
.provising-device-select .ant-select-selector span.ant-select-selection-placeholder{
display: flex;
align-items: center;
}
.provising-device-select .ant-select-selector .ant-select-selection-item{
font-size: 1rem;
display: flex;
align-items: center;
/* justify-content: center; */
font-weight: 400;
}
/* .provising-device-select .ant-select-selector .ant-select-selection-search {
  height: 100%;
  width: 100%;
} */

.provisioning-continue-btn-div {
  margin-top: 18px;
}

.provisioning-continue-btn-div div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.provisioning-continue-btn-div div button {
  max-width: 520px;
  /* padding: 11px 0px; */
}

.provisioning-guided-btn {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  padding-bottom: 2.5rem;
}

.provisioning-guided-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.provisioning-guided-btn div button {
  max-width: 520px;
  /* padding: 11px 0px; */
}

.bottom-content-wrapper {
  margin: 52px 0px 35px 0px;
}

.page-main-content {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 18px;
}

.page-sub-content {
  font-weight: 400;
  font-size: 16px;
}
@media only screen and (max-width: 320px) {
  .provisioning-guided-btn {
    position: absolute;
    /* bottom: 10px; */
    left: 0;
  }
  
}

@media only screen and (min-width: 520px) {
  .provisioning-guided-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  .bottom-content-wrapper {
    margin: 31px 0px 35px 0px;
  }
}
@media only screen and (min-width: 540px) {
  .provisioning-subContent{
    margin-bottom: 70px;
  }
  .provisioning-heading span{
    font-size: 32px !important;
  }
 .provisioning-continue-btn-div{
    margin-bottom: 70px;

  }
}


@media only screen and (min-width: 760px) {
  .provisioning-continue-btn-div{
    margin-top: 2rem;
  }
}

@media only screen and (max-height: 760px) {
  .provisioning-guided-btn{
    position: relative !important;
  }
}