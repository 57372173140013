.add-system-qr-code-wrapper {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  font-family: "Poppins", sans-serif;
}
/* .add-system-navigatebtn-div{
  width: 100%;
  max-width: 1024px;
} */
.qr-code-body-wrapper{
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.add-system-qr-code-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width: 720px;
  position: relative;
}
.add-system-qr-heading {
  margin-bottom: 10px;
}
.add-system-qr-heading span{
  font-size: 27px !important;

}

.add-system-qr-sub-content p {
  margin-bottom: 38px;
}

.qrscan-btn-div div {
  margin-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrscan-btn-div div button {
  max-width: 520px;
  /* padding: 11px 0px; */
}

.add-system-qr-bottom-content {
  margin-bottom: 28px;
}

.add-system-no-qr-btn{

}
.add-system-no-qr-btn div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-system-no-qr-btn div button{
  max-width: 520px;
  /* padding: 11px 0px; */
  border-radius: 10px
}

@media only screen and (max-width: 320px) {
  .qrscan-btn-div div {
    margin-bottom: 45px;
  
  }
}
@media only screen and (min-width: 520px) {
  .add-system-qr-heading span{
    font-size: 32px !important;
  
  }
}