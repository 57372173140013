.inputField{
    min-width: 300px;
    width: 400px;
    max-width: 400px;
    margin-top: 20px;
}

.fieldContainer{
    height: calc(100vh - 80px);
}
// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  
    .inputField{
        width: 200px;
        max-width: 400px;
        margin-top: 20px;
        
    }
 }

// Medium devices (tablets, 768px and up)


// Large devices (desktops, 992px and up)

@media (min-width: 768px) and (max-width: 768px) {
    
    .inputField{
        width: 400px;
        max-width: 400px;
        margin-top: 20px;
    }
}
.imageUploadHeaderText {
    margin-right: 8px;
}
.imageUploadInfoIcon {
    margin-bottom: 3px;
}
.imageDisplayedClass {
    height: 100px;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 6px;
}
.imageUploadButton {
    margin-top: 8px;
    margin-bottom: 20px;
}