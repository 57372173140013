.create-profile-container {
  max-width: 480px !important;
}
.create-profile-screen {
  background-image: url("../common_features/assets/triangle.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: bottom right;
  /* background-size: 49%; */
}
.create-profile-screen-title {
  text-align: left;
  margin-top: 80px !important;
  margin-bottom: 16px !important;
}
.edit-profile-screen-title {
  text-align: left;
  margin-top: 30px !important;
  margin-bottom: 16px !important;
}
.email-screen-detail {
  margin-bottom: 16px;
}
.create-profile-input-wrapper {
  max-width: 590px !important;
  margin: 0 auto 0 !important;
}
.create-profile-button-container {
  max-width: 597px !important;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  /* box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.04); */
  padding: 40px 40px;
  /* background-color: #fff !important; */
}
.change-password-terms {
  display: flex;
  margin-bottom: 30px;
}
.change-password-validation {
  margin-bottom: 10px;
}
.create-profile-button-wrapper {
  max-width: 480px;
  margin: auto;
}
.cancel-profile-button-wrapper {
  max-width: 480px;
  margin: auto;
  margin-top: 20px;
}
.change-password-confirm-input-wrapper {
  margin-bottom: 30px;
}
.change-password-tips-wrapper {
  max-width: 478px;
  display: flex;
  margin-bottom: 35px;
}
.change-password-privacy-notice {
  max-width: 480;
}
.email-forgot-password-text {
  text-align: right;
  margin-top: 15px;
}
.create-profile-element-container {
  margin-bottom: 24px;
  position: relative;
}
.create-profile-zip-country-container {
  display: flex;
  margin-bottom: 24px;
}
.create-profile-zip-container {
  max-width: 480px;
  margin-bottom: 24px;
  position: relative;
}

.create-nav-button-div div button {
  border-radius: 0px !important;
  margin-bottom: 13px !important;
}
.create-profile-element-container.P-error
  span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: red !important ;
  box-shadow: none !important;
}
.create-profile-zip-container.P-error
  span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: red !important ;
  box-shadow: none !important;
  
}
.create-profile-phone-number.P-error .react-tel-input .form-control {
  
    border-color: red !important;
    box-shadow: none !important;
  
}
.input-warningIcontwo {
  position: absolute;
  top: 38px;
  right: 12px;
  z-index: 1;
}
/* tablet */
@media only screen and (max-device-width: 1024px) {
  .create-profile-input-wrapper {
    margin: 10px auto 0 !important;
    max-height: calc(100vh - 340px);
    overflow: auto;
  }
  .email-screen-detail {
    margin-bottom: 10px;
  }
  .create-profile-button-container {
    max-width: 100% !important;
    background-color: #fff !important;
  }
  .create-profile-zip-country-container {
    display: block;
  }
  .create-profile-zip-container {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 24px;
  }
}

@media only screen and (max-device-width: 767px) {
  .create-profile-screen-title {
    text-align: left;
  }
  .create-profile-screen {
    background-size: 50%;
  }
  .create-profile-button-container {
    max-width: 100% !important;
    background-color: transparent !important;
    box-shadow: none;
    position: relative;
  }
  .create-profile-input-wrapper {
    max-width: 100% !important;
    margin: auto !important;
    max-height: 100%;
    overflow: hidden;
  }
}
@media only screen and (max-device-width: 320px) {
  .create-profile-screen-title {
    margin-top: 0px !important;
  }
}
