.email-screen {
  background-image: url("../common_features/assets/triangle.png");
  background-repeat: no-repeat;
  min-height: 100vh;
  background-position: bottom right;
  width: 60% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.email-screen .wellcome-row {
  display: block;
}
.enter-email-main-container{
  height: 100vh;
  overflow: hidden;
}
.spinner-wrapper {
  height: 100vh !important;
}
.welcome-scr{
  /* background-image: url("../common_features/assets/triangle.png"); */
  min-height: 100vh;
  width:100%;
}
.welcome-scr .welcome-scr-banner{
  width:40%;
  position: relative;
}

.welcome-image-container{ 
  padding:0px !important;
}
.welcome-scr .welcome-scr-banner img {
  object-fit: inherit;
  height: 100%;
}
.email-screen .welcome-logo-container {
  max-width: 590px;
  margin: 0px auto 0 !important;
}
.enter-email-header-container {
  display: none;
}

.email-screen-title {
  text-align: left;
  max-width: 590px !important;
  margin: 35px auto 0 !important;
}
.email-input-wrapper {
  max-width: 590px !important;
  margin: 16px auto 0 !important;
}
.email-button-container {
  max-width: 597px !important;
  margin: 0 auto;
  width: 100%;
  padding: 48px 40px;
}
/* .email-button-wrapper {
  max-width: 335px;
} */
.email-forgot-password-text {
  display: flex;
  justify-content: left;
  margin-top: 15px;
}
.email-password-container {
  position: relative;
  margin-bottom: 25px;
}
.email-password-container.invalid-mail
  span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
  border-color: #E00932 !important ;
  box-shadow: none !important;
}
.input-currentwarningIconn {
    height:16px;
    width: 16px;
    position: absolute;
    top: 50px;
    right: 12px;
    z-index: 1;
    margin-right: 20px;
  }
  
  .welcome-user-container{
    
    max-width: 100% !important;
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* tablet */
@media only screen and (min-device-width: 1024px) {
  .email-button-wrapper{
    max-width: 75%;
    margin-left: 75px;
  }
}
@media only screen and (max-device-width: 1024px) {
  .enter-email-header-container {
    display: block;
  }
  .email-screen .welcome-logo-container {
    display: none !important;
  }

  /* .enter-email-main-container .welcome-scr {
    min-height: auto !important;
  } */
  /* .enter-email-main-container .welcome-user-container {
    max-height: calc(100vh - 80px) !important;
  } */

  .email-input-wrapper {
    margin: 10px auto 0 !important;
  }
  .email-screen-detail {
    margin-bottom: 54px;
  }
  .email-button-container {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .email-screen-container .welcome-image-container.welcome-scr-banner {
    display: none;
  }
  .email-screen {
    width: 100% !important;
    display: block;
    position: relative;
    min-height: calc(100vh - 80px) !important;
  }
  .email-button-wrapper {
    max-width: 100%;
  }
  .email-forgot-password-text {
    text-align: left;
    margin-top: 45px;
  }
}

@media only screen and (max-device-width: 767px) {
  .email-screen-title {
    text-align: left;
  }
  .email-screen {
    background-size: 50%;
  }
  .email-button-container {
    max-width: 100% !important;
    background-color: transparent !important;
    box-shadow: none;
    position: absolute;
    bottom: 75px;
    padding: 48px 12px;
  }
  .email-input-wrapper {
    max-width: 100% !important;
    margin: auto !important;
  }
  .email-forgot-password-text {
    text-align: left;
    margin-top: 45px;
  }
}
@media only screen and (max-device-width: 320px) {
  .email-screen-title {
    margin-top: 10px !important;
  }
  .email-button-container {
    position: bottom;
    padding: 20px 10px;
  }
  .email-button-wrapper {
    max-width: 100%;
  }
  .email-forgot-password-text {
    text-align: left;
    margin-top: 45px;
  }
}
@media only screen and (max-device-width: 280px) {
  .email-button-container {
    position: bottom;
    padding: 20px 10px;
    margin-top: 100px;
  }
  .email-button-wrapper {
    max-width: 100%;
  }
  .email-forgot-password-text {
    text-align: left;
    margin-top: 45px;
  }
}
