.contact-pentair-container{
    max-width: 480px !important;
}
.contact-pentair-screen{
    /* background-image: url('../common_features/assets/triangle.png'); */
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: bottom right;
    /* background-size: 49%; */
    
}
.contact-pentair-screen-title{
    text-align: left;
    margin-top: 26px !important;
    margin-bottom: 16px !important;
}

.dropdown-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #BFC1C4;
    border-radius: 10px;
    margin-top: 10px;
    padding:10px
}
.contact-pentair-element-container{
    margin-bottom: 24px;
}

.contact-pentair-input-wrapper{
    /* display:flex; */
    max-width: 590px !important;
    margin: 0 auto 0 !important;
}
.dropDown-desktop-view{
    display: flex;
    margin-top: 10px;

}
.text-area-container {
    margin-top: 25px !important;
    max-width: 590px !important;
    /* margin: 0 auto 0 !important; */
}
.text-area{
    display: flex;
    max-width: 590px !important;
    margin-top: 10px;
}

@media only screen and (min-device-width: 1024px) {
    .contact-pentair-input-wrapper{
        margin: 10px auto 0 !important;
        max-height: calc(100vh - 340px);
        overflow: auto;
    }
    .dropdown-mobile-container{
        display: none;
    }
    .dropDown-desktop-view{
        display: flex;
        margin-top: 10px;
    }
}

.contact-pentair-button-container{
    max-width: 597px !important;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    padding: 40px 40px;
    margin-top: 100px;
}

.contact-pentair-button-wrapper{
    max-width:480px;
    margin: auto;
}
.dropDown-desktop-view{
    display: flex;
}

@media only screen and (max-device-width: 767px) {
    .contact-pentair-screen-title{
        text-align: left;
    }
    .dropdown-wrapper{
        display: flex !important;
        max-width: 100% !important;
        margin: auto !important;
        max-height: 100%;
        overflow: hidden;
        margin-bottom: 30px;
        padding: 10px
    }
    .dropdown-mobile-container{
        margin-top: 10px;
    }
    .contact-pentair-input-wrapper{
        max-width: 100% !important;
        margin: auto !important;
        max-height: 100%;
        overflow: hidden;
    }
    .dropDown-desktop-view{
        display: none;
    }
    .text-area{
        max-width: 100% !important;
        margin-top: 10px !important;

    }
    .text-area-container {
        margin-top: 25px !important;
        max-width: 100% !important;
    }
    .contact-pentair-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        position: relative;
        /* margin-top: 60px; */

    }

}
@media only screen and (min-device-width: 768px) {
    .contact-pentair-screen-title{
        text-align: left;
    }
    .dropdown-wrapper{
        display: none;
    }
    .dropDown-desktop-view{
        display: flex;
    }
    .text-area{
        max-width: 100% !important;
        margin-top: 10px !important;

    }
    .text-area-container {
        margin-top: 25px !important;
        max-width: 100% !important;
    }
    .contact-pentair-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        position: relative;
        margin-top: 60px;

    }

}

@media only screen and (max-device-width: 320px) {

    .dropdown-wrapper{
        display: flex !important;
        max-width: 100% !important;
        margin: auto !important;
        max-height: 100%;
        overflow: hidden;
        margin: 10px

    }
    .dropdown-mobile-container{
        margin-top: 10px;
    }
    .dropDown-desktop-view{
        display: none;
    }
}