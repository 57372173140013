.legal-screen {
    /* display: flex;
    /* width: 540px; */
    /* justify-content: center;
    align-items: center; */ 
  }
  
  .legal-container{
    max-width: 480px !important;
    margin-top: 40px
  
  }
  .create-nav-button div button {
      border-radius: 0px !important;
      /* margin-bottom: 13px !important; */
    }