[class^="table_noDataFoundContainer"]{
    min-height: 250px !important;
}
.store-image-div-web {
    display: flex;
    margin: 12px 0px 28px 0px;
    border-radius: 10px;
    height: 165px;
    width: 250px;
    align-items: center;
    background-color: #F4F4F4;
    cursor: pointer;
}
.web-screen-zoom .ant-modal-content {
    width: 125%;
    margin: 50px 30px -20px -35px;
}
.web-screen-zoom .cust-cross-icon {
    margin-top: -60px;
    right: 6px;
}
.web-screen-zoom .ant-modal-body {
    padding: 0px;
    height: 700px;
}
.system-add-btn {
    width: 100%;
}
.storeNameDiv-Text {
    width: 78%;
}
.editStoreBtn-style .button-title-text {
    margin-left: 8px !important;
}
.userTaggingBtn .button-title-text {
    margin-top: 2px !important;
}
// ::-webkit-scrollbar {
//     width: 5px;
//   height: 8px; 
//  }

// ::-webkit-scrollbar-track {
//   background-color: #e9f2f6;
//   height: 10px;
//   border-radius: 15px;
// }

// ::-webkit-scrollbar-thumb {
//   background: #006E96;
//   border-radius: 15px;
// }