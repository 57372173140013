.addAccessory-wrapper {
  font-family: "Poppins", sans-serif;
}
.addAccessory-body-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
}
.addAccessory-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width: 720px;
  position: relative;
}
.addAccessory-heading-2 {
  margin-top: 10px;
}

.addAccessory-heading span {
  font-size: 27px !important;
}
.addAccessory-subContent {
  margin-top: 10px;
  margin-bottom: 29px;
  font-weight: 400;
  font-size: 16px;
}
.addAccessory-btn {
  /* position: absolute; */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  padding-bottom: 2.5rem;
}

.addAccessory-btn div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.addAccessory-btn div button {
  max-width: 520px;
}
.addAccessory-list-wrapper {
  min-height: calc(100vh - 370px);
  margin-bottom: 20px;

}
.accessory-list-body {
  border-bottom: 1px solid #bfc1c4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 5px;
}
.accessory-list-body button {
  padding: 0px !important;
}
.accessory-leftpart {
  display: flex;
  align-items: center;
}
.accessory-rightpart {
  display: flex;
  align-items: center;
}
.accessory-image {
}
.accessory-title {
  font-size: 16px;
  font-weight: 600;
}
.accessory-plusBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none !important;
  outline: none !important;
}
.accessory-input {
  width: 40px;
  height: 46px;
  margin: 0px 13px;
  text-align: center;
  border: 1px solid #bfc1c4;
  border-radius: 10px;
  background: #f4f4f4;
}
.accessory-minusBtn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none !important;
  outline: none !important;
}
.edit-input-label{
  font-size: 16px;
  font-weight: 600;
}
.PageCount {
  padding-left: 22px;
  padding-top: 15px;
  font-family: "poppins";
  font-size: 18px
}
@media only screen and (min-width: 380px) {
  .addAccessory-btn {
    /* position: absolute;
    bottom: 10px;
    left: 0; */
    /* padding-bottom: 2rem; */
  }
}
@media only screen and (min-width: 360px) {
  .addAccessory-btn {
    /* position: absolute;
    bottom: 10px;
    left: 0; */
    /* padding-bottom: 2rem; */
  }
}

@media only screen and (min-width: 320px) {
  .addAccessory-btn {
    /* position: absolute; */
    /* bottom: -90px; */
    /* left: 0; */
    /* padding-bottom: 10px; */
    /* bottom: 10px; */
  }
}
@media only screen and (max-width: 320px) {
  .addAccessory-btn {
    /* position: absolute; */
    /* bottom: -90px; */
    /* left: 0; */
    /* padding-bottom: 10px; */
    /* bottom: 10px; */
  }
}
