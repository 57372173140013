.popupState {
  box-sizing: border-box; 
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px 0px 0px;
  position: absolute;
  /* width: 336px; */
  height: 76px;
  left: 20px;
  right: 20px;
  top: 90px;
  background: #E3EBD8;
  border: 1px solid #4A7B09;
  box-shadow: 0px 2px 0px #EBEBEB;
  border-radius: 10px;
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@media only screen and (min-width: 720px){
  .popupState {
    width: 34% !important;
    margin: 0px auto;
  }
}
@keyframes cssAnimation {
    to {
        width:0;
        height: 0;
        overflow:hidden;
        z-index: -10;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
        z-index: -10;
    }
}
.popupState > .popUpShow {
  width: 15px;
  height: 76px;
  background: #4A7B09;
  border-radius: 10px;
  flex: none;
}
.statusText {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}
.systems-text {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 20px;
}
.systems-label-text {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  margin-left: 20px
}
.popUpImage{
  display: flex;
  margin-left: 12px;
}
.disStore-bold {
  font-weight: 700;
}
.disStore-content {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin-top: 40px;
  font-weight: 400;
  margin-bottom: 216px;
}

.disStore-subContent {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}
.disStore-color {
  color: #2a6f96;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.disStore-bottomContent {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.disStore-bottomContent a {
  text-decoration: none;
 }

.dispatcherStore-body {
  padding: 20px;
  max-width: 1024px;
}

.dispatcherStore-headingdiv {
  margin-top: 40px;
}

.dispatcherStore-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.disStore-homeDashWrapper {
  width: 100%;
  max-width: 1024px;
}

.disStore-iconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.disStore-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}
.disStore-left > div {
  padding: 0px 15px;
  height: 100%;
}
.disStore-left > div:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.disStore-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20%;
}
.disStore-right > div {
  padding: 1px 15px;
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .disStore-right {
    width: 15%;
  }
  .disStore-left {
    width: 85%;
  }
}

/* TECH DISPATCHER */
@media only screen and (min-width: 1024px) {
  .techDispatcher-body {
    min-height: calc(100vh - 115.5px);
    width: 70%;
    position: relative;
    margin: 0px auto;
  }
  .store-image-div {
    width: 28% !important;
    height: 190px !important;
  }
  .nav-back-btn-wrapper {
    margin-bottom: 20px !important;
    width: 100%;
  }
  .navigate-back-navigate {
    cursor: pointer;
  }
  .techDispatcher-mainHeading {
    width: 60%;
    margin: 0px auto;
  }
  .mobile-screen-zoom .ant-modal-content {
    width: 125% !important;
    margin: 50px 30px -20px -35px !important;
  }
  .mobile-screen-zoom .cust-cross-icon {
    margin-top: -60px !important;
    right: 6px !important;
  }
  .mobile-screen-zoom .ant-modal-body {
    padding: 0px !important;
    height: 700px !important;
  }
}

.techDispatcher-wrapper {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  font-family: "Poppins", sans-serif;
}
.homeDashboard-navigate {
  padding: 20px 20px 20px 40px;
}
.navigate-content {
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  margin-left: 18px;
}
.techDispatcher-body {
  min-height: calc(100vh - 115.5px);
  width: 100%;
  max-width: 1024px;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
}

.techDispatcher-mainHeading {
  text-align: left;
  padding: 0px 20px;
  margin-bottom: 40px;
}
.techDispatcher-subHeading {
  text-align: center;
  padding: 0px 20px;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
.techDispatcher-bottomContent {
  text-align: center;
  padding: 0px 20px;
  color: #006e96;
  font-weight: 700;
  font-size: 13px;
  margin-top: 14px;
  margin-bottom: 30px;
}
.techDispatcher-subContent {
  text-align: center;
  padding: 0px 20px;
  font-weight: 400;
  font-size: 13px;
}
.techDispatcher-button {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  padding: 0px 20px;
  padding-bottom: 2rem;
}
.techDispatcher-button div {
  display: flex;
  justify-content: center;
}
.techDispatcher-button div button {
  max-width: 520px;
}
.techDispatcher-imagediv {
  text-align: center;
  margin-bottom: 20px;
}

.hubsystem-wrapper{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 30px;
  padding-bottom:15px ;
  border-bottom: 1px solid #EBEBEB;;
}
.hub-btn-div{
font-size: 13px;
font-weight: 600;
color:#006E96 ;
margin-right: 20px;
}
.hub-btn-div span{
margin-right: 5px;
}
.system-btn-div{
  font-size: 13px;
  font-weight: 600;
  color:#006E96 ;
  cursor: pointer;
}
.system-btn-div span{
margin-right: 5px;

 }
 .dispatcherStore-wrapper .storeTile-navigateIcon {
  cursor:  pointer !important;
}
.store-image-div {
  display: flex;
  margin: 20px 20px 0px 20px;
  border-radius: 10px;
  width: 90%;
  height: 117px;
  align-items: center;
  background-color: #F4F4F4;
  cursor: pointer;
}
.mobile-screen-zoom .ant-modal-content {
  margin: 50px 30px 80px 30px;
}
.mobile-screen-zoom .cust-cross-icon {
  margin-top: -60px;
  right: 6px;
}
.mobile-screen-zoom .ant-modal-body {
  padding: 0px;
  height: 500px;
}
