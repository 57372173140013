/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap"); */
.storeDashboard-mainHeading {
  font-size: 27px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  line-height: 34px;
  padding: 20px;
}

.storeDashboard-wrapper {
  font-family: "Poppins", sans-serif;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

.storeDashboard-content {
  font-family: "Poppins", sans-serif;
  padding: 20px;

  margin-bottom: 100px;
}

.storeDash-subcontent {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  padding: 0px 20px;
  margin-bottom: 10px;
}
.systems-text{
  font-size:18px;
  font-weight:600; 
  color:#333333; 
  margin-left: 20px
}
.storeDash-bottom-content {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  line-height: 25px;
  font-weight: 400;
  padding: 0px 20px;
  padding-bottom: 2rem;
}
.content-bold {
  font-weight: 700;
}

.content-colorbold {
  font-weight: 700;
  color: #2a6f96;
}
.storeDashboard-body {
  width: 100%;
  max-width: 1024px;
}

.ownerDashboard-body {
  width: 100%;
  max-width: 520px;
}

.ownerDashboard-wrapper {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.storeDashboard-navigate {
  padding: 20px 20px 0px 40px;
}
.navigate-content {
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  margin-left: 18px;
}


@media only screen and (min-width: 768px) {
  .ownerDashboard-body {
    width: 100%;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1024px) {
  .ownerDashboard-body {
    width: 100%;
    max-width: 1024px;
  }
}

.offline{
  background-color: rgb(235 172 172);
  color: rgb(147 38 38);
  content: "offline";
  font-family: "Poppins-Bold";
  font-size: 12px;
  content: "online";
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.online{
  background-color: rgb(193, 238, 192);
  color: rgb(22, 102, 58);
  font-family: "Poppins-Bold";
  font-size: 12px;
  content: "online";
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.store-image-div {
  display: flex;
  margin: 20px 20px 0px 20px;
  border-radius: 10px;
  width: 90%;
  height: 117px;
  align-items: center;
  background-color: #F4F4F4;
  cursor: pointer;
}
.mobile-screen-zoom .ant-modal-content {
  margin: 50px 30px 80px 30px;
}
.mobile-screen-zoom .cust-cross-icon {
  margin-top: -60px;
  right: 6px;
}
.mobile-screen-zoom .ant-modal-body {
  padding: 0px;
  height: 500px;
}
@media only screen and (min-width: 1024px) {
  .store-dashboard-body {
    width: 60%;
    margin: 0px auto;
  }
}