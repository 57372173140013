.speed-setting-wrapper{

  font-family: "Poppins", sans-serif;
}
.speedSetting-body-wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 20px 20px 20px;
}
.speedSetting-body{
    min-height: calc(100vh - 187px);
    width: 100%;
    max-width: 720px;
    position: relative;
}
@media only screen and (min-width: 1024px) {
  .speedSetting-body{
    text-align: center;
  }
}
.speedSetting-heading span{
    font-size: 27px !important;
}
.speedSetting-subContent{
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
}
.speedSetting-btnDiv-wrapper{
    /* width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; */
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
    min-height: calc(100vh - 445px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    align-content: flex-start;
}
.speedSettingBtn{
width: 150px;
height: 40px;
background: #FFFFFF;
border: 1px solid #333333;
border-radius: 30px;
margin-right:10px;
/* padding: 0px 10px; */
margin-bottom: 15px;
outline: none;
position: relative;
}
.speedSettingBtn-selected{
    background: #F0F8FE !important;
    border: 2px solid #008ABC !important;
}
.speedSettingBtn-disabled{
  background: #F4F4F4;
  border: none;
  outline: none;
}
.speedSetting-notSelected{
    background: #F4F4F4;
    border: none;
    outline: none;
    
}

.speedSetting-conti-btn {
    margin-top: 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    width: 100%;
    /* position: absolute; */
    margin-bottom: 2rem;

  }
  
  .speedSetting-conti-btn div {

  display: flex;
  justify-content: center;
  align-items: center;
  }
  .speedSetting-conti-btn div button {
    border-radius: 10px;
    /* margin-top: 25px; */
    max-width: 520px;
  }
.customTick{
position: absolute;
left: 12px;
}
.model-number-label{
     margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
}
  @media only screen and (min-width: 380px) {
    .speedSetting-conti-btn{
        /* position: absolute; */
        /* bottom: 10px; */
        /* left: 0; */
    }
  }
  @media only screen and (min-width: 360px) {
    .speedSetting-conti-btn {
        /* position: absolute; */
        /* bottom: 10px; */
        /* left: 0; */
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .speedSetting-conti-btn {
        margin-left: 9px !important;
    }
  }
  @media only screen and (max-width: 320px) {
    .speedSetting-conti-btn {
        /* position: absolute;
 
    left: 0; */
    }
  }
  