.system-detail-dashboard-navigate {
  font-family: "Poppins", sans-serif;
  display: flex;
  margin-top: 10px;
  margin-left: 29px;    
}
.seperator{
  height: 24px;
  margin-left:10px;
border: 1px solid #BFC1C4;
.edit-image{
  display: flex;
}
}
.storeInfomation-infor {
  width: Hug (337px);
  height: Hug (48px);
  top: 523px;
  left: 20px;
  padding: 12px, 16px, 12px, 16px;
  border: 1px;
  gap: 14px;
}
.service-tile {
  width: '337px';
  height: '58px';
  border: '1px solid #EBEBEB';
  padding: '12px, 16px, 12px, 16px';
  box-shadow: ' 0px 2px 0px 0px #EBEBEB'
}
.service_tile {
  /* margin-left:20px;
  margin-right:20px; */
  margin: 5px;
  align-items: center;
}

.storeTile-wrapper {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
}
.suffix_style{
  max-width: 50px;
  height: 22px;
  top: 1025.5px;
  /* //left: 309px; */
  color: #9A9A9A;
}
.pretreatment-container{
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;
}
.other-notes {
  margin-top: 40px;
}
.subContent {
  width: 335px;
  left: 22px;
  margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
}
.speedSetting-body-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  margin-top: 10px;
}
.speedSetting-body-inner-wrapper{
  padding: 0;
}
.main-header {
  margin-top: 20px;
}
.container-style {
  margin-top: 20px;
}
.system-detail-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}
.completeHeaderText{
  color: black; 
  font-weight: 600; 
  font-size: 18px; 
  font-family: Poppins; 
  margin-left: 22px; 
  margin-top: 17px;
  margin-bottom: 29px;
}
.system-detail-body{
  width: 100%;
  max-width: 1024px;
  position: relative;
  min-height: calc(100vh - 115.5px);
}
.breakLine {
  background: #D3D3D3;
  height: 1px;
}
.currentStep {
  font-size: 16px;
  font-weight: 600; 
  margin-right: 4px;
}
.totalStep {
  font-size: 16px;
}
.system-map-tile-wrapper {
  margin: 23px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
.system-detail-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}
.system-detail-body{
  width: 100%;
  max-width: 1024px;
  position: relative;
  min-height: calc(100vh - 115.5px);
}
.navigate-content {
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  margin-left: 18px;
}

.edit-sensor-accessory-text{
  display: flex;
  border-bottom: 1px solid #EBEBEB;
  padding: 10px 20px;
  margin-left: 4px;
}
.edit-water-photos-text{
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 22px;
}
.outer-div-text{
display: flex;
align-items: center;
cursor: pointer;
}

.outer-right-border{
border-right: 1px solid  #BFC1C4;
padding-right: 14px;
}
/* .edit-text{
  margin-left: 19px;
} */
.stepClass {
  display: flex;
  padding: 0px 15px 0px 10px;
  margin: 20px 20px 0px 20px;
  font-family: "Poppins", sans-serif;
}

.plus-icon{
  margin-left: 7px;
}
.edit-icon{
  margin-left: 7px;
}
.edit-water-info-text{
  font-size: 13px;
  color: #006e96;
  font-weight: 700;
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.edit-water-text{
  font-size: 13px;
  color: #006e96;
  font-weight: 700;
  margin-left: 12px;
}
.edit-sensor-text{
  font-size: 13px;
  color: #006e96;
  font-weight: 700;
  margin-left: 9px;
  margin-right: 9px;
}

.edit-accessory-text{
  font-size: 13px;
  color: #006e96;
  font-weight: 700;
  margin-left: 8px;
  margin-top: 2px;
}

.alert-tile-container-wrapper{
margin: 16px 6px 10px;
display: grid;
grid-template-columns: repeat(2, 1fr);
/* column-gap: 20px; */
}
.alert-tile-container-wrapper-withoutLength{
  margin: 5px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* column-gap: 20px; */
}
.remove-system-btn{
  display: flex;
  justify-content: center;
  padding: 0px 20px 40px 20px;
  cursor: pointer;
}
.partial-continue-btn {
  display: flex;
  justify-content: center;
  padding: 0px 20px 25px 20px;
  cursor: pointer;
}
.partial-continue-btn div{
  width: 100%;
  max-width: 520px;
}
.partial-continue-btn div button{
  color: #FFFFFF !important;
  border-color: #006e96 !important;
  border: 2px solid;
  background-color: #006e96;
}
.remove-system-btn div{
  width: 100%;
  max-width: 520px;
}
.remove-system-btn div button{
  color: #e00932 !important;
    border-color: #e00932 !important;
    border: 2px solid;
}
@media only screen and (max-width: 520px) {
.system-map-tile-wrapper {
  grid-template-columns: repeat(1, 1fr);
}
.alert-tile-container-wrapper {
  grid-template-columns: repeat(2, 1fr);
}
}

@media only screen and (max-width: 320px) {
.system-map-tile-wrapper {
  grid-template-columns: repeat(1, 1fr);
}
.alert-tile-container-wrapper{

  /* margin: 20px; */
  display: grid;

  /* grid-template-columns: repeat(2, 1fr); */
  column-gap: 20px;
}
.edit-water-text{
  font-size: 10px;
  margin-left: 10px;
}
.edit-sensor-text{
  font-size: 10px;
  color: #006e96;
  font-weight: 700;
  margin-left: 9px;
  margin-right: 9px;
}

.edit-accessory-text{
  font-size: 10px;
  color: #006e96;
  font-weight: 700;
  margin-left: 8px;
  margin-top: 2px;
}


}

@media only screen and (min-width: 1024px) {
.system-map-tile-wrapper {
  max-width: 1024px;
}
.alert-tile-container-wrapper {
  max-width: 1024px;
}
.edit-sensor-accessory-text{
  display: flex;
  border-bottom: 1px solid #EBEBEB;
  padding-top: 10px;
  padding-bottom: 10px;
}
.edit-water-text{
  font-size: 16px;
  margin-left: 10px;
}
}

.system-detail-wrapper .nav-back-btn-wrapper {
  width: 100% !important;
}

@media only screen and (min-width: 760px) {
.system-map-tile-wrapper {
  max-width: 1024px;
}
.alert-tile-container-wrapper {
  max-width: 1024px;
}
.edit-sensor-accessory-text{
  padding: 10px 20px;
  margin-left: 4px;
}
.speedSetting-body-inner-wrapper{
  width: 50%;
}
.other-notes {
  margin-top: 40px;
  width: 75%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.edit-water-text{
  font-size: 16px;
  margin-left: 10px;
}
.edit-sensor-text{
  font-size: 16px;
  margin-left: 9px;
  margin-right: 9px;
}

.edit-accessory-text{
  font-size: 16px;
  font-weight: 700;
  margin-left: 8px;
  margin-top: 2px;
}
}
@media only screen and (min-width: 720px) {
  .alert-tile-container-wrapper .alert-tile-wrapper {
    padding: 10px;
    margin: 14px;
    justify-content: space-between;
    width: 90%;
    height: 50px;
    background-color: #FCE6EB;
  }
.service-tile {
    width: 1030px;
    height: 56px;
    padding: 16px;
}
}
.system-map-tile-wrapper .arrow-right-style {
  cursor: pointer;
}
.ok-alert-tile-wrapper {
  cursor: auto !important;
}
.ok-alert-tile-wrapper-Partial {
  cursor: auto !important;
}





