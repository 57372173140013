@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap");

.od-wrapper {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-family: "Poppins", sans-serif;
  /* flex-direction: column; */
  /* padding: 20px; */
}
.od-body-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
}
.od-body{
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 229.5px);
  width: 100%;
  max-width: 720px;
  position: relative;
}
.ownerFirstVisitImage {
  margin-top: 45px;
  margin-bottom: 38px;
}

.od-sub-content {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

.od-main-content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  margin-top: 10px;
}
/* .storeDashboart-addStorebtn {
  width: 100%;

  margin-top: 160px;
  padding-bottom: 2rem;
} */
.storeDashboart-addStorebtn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  /* padding: 0px 20px; */
  /* padding-top: 1.5rem; */
  position: absolute;
  margin-top: 30px;
}
.storeDashboart-addStorebtn div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.storeDashboart-addStorebtn div button {
  max-width: 520px;
}

@media only screen and (min-width: 320px) {
  .storeDashboart-addStorebtn {
    bottom: 10px;
    left: 0;
  }
  .ownerFirstVisitImage{
    margin-top: 0px;
  }
}
@media only screen and (min-width: 376px) {
.storeDashboart-addStorebtn {
  bottom: 10px;
  left: 0;
}
}
@media (min-width:412px) and (max-width:414px) {
.storeDashboart-addStorebtn {
  position: absolute;
  /* bottom: -50px; */
  left: 0;
}
}

@media (min-width:520px) and (max-width:541px) {
.storeDashboart-addStorebtn {
  position: absolute;
  bottom: -60px;
  left: 0;
}
}


@media only screen and (min-width: 520px) {
  /* .storeDashboart-addStorebtn {
    position: fixed;
    bottom: 10px;
  } */
  .ownerFirstVisitImage {
    height: 200px;
    width: 258px;
  }
  .od-sub-content {
    font-size: 25px;
    margin-top: 40px;
    margin-bottom: 12px;
  }
  .od-main-content {
    font-size: 20px;
  }
  /* .storeDashboart-addStorebtn {
    position: fixed;
    bottom: 10px;
  } */
}
