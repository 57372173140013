.ownerMobileIcon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}

.ownerMobileIcon-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ownerMobileIcon-iconWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ownerMobileIcon-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  font-weight: 700;
  margin-left: 10px !important;
}
.ownerMobileIcon-left > div {
  padding: 0px 15px;
  height: 100%;
}
/* .ownerMobileIcon-left > div:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
} */
.ownerMobileIcon-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
.ownerMobileIcon-right > div {
  padding: 1px 15px;
  height: 100%;
}
.ownerMobileIcon-right > div:first-child {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}
.ownerMobileIcon-storeContent {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #006e96;
  margin-left: 4.5px;
}
.ownerDashboard-wrapper .storeTile-navigateIcon {
  cursor:  pointer !important;
}
@media only screen and (min-width: 1024px){
  .ownerDashboard-wrapper {
    margin: 0px auto;
    width: 60%;
  }
}
.handleCreateStorediv-class {
  cursor: pointer;
}
