.Toastify__toast--error {
    border: 1px solid #e00932;
    border-left-width: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    .toast-ccw-message {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        margin-left: 7px;
        color: #e00932;
    }
}