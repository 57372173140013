.force-change-password-screen {
    background-image: url('../common_features/assets/triangle.png');
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: bottom right;    
}
.email-screen .welcome-logo-container {
    max-width: 590px;
    margin: 0px auto 0 !important;
  }
  .forgot-password-container {
    text-align: left;
    margin-bottom: 65px;
    max-width: 590px !important;
    margin: 40px auto 0 !important;
  }
.forgot-password-header-container{
    display: none;
}
.banner-text {
    position: absolute;
    bottom: 0px;
    font-family: Poppins;
    max-width: 432px;
    left: 48px;
    bottom: 120px;
    display: flex;
    flex-direction: column;
}
.forgot-password-screen-title{
    text-align: center;
    margin-top: 40px !important;
    max-width: 335px;
    margin-bottom: 10px;
}
.forgot-password-screen-detail{
    margin-bottom: 47px;
    max-width: 325px;
    display: flex;
    flex-direction: column;
}
.forgot-password-input-wrapper{
    max-width: 590px !important;
}
.reset-password-button-container{
    max-width: 597px !important;

    margin: 0 auto;
    width: 100%;
    padding: 48px 0px;
}
.reset-password-button-wrapper{
    max-width:535px ;
    margin-left: 0px;
    margin-top: 16px;
}

.forgot-password-screen-detail p{
    margin-bottom: 0 !important;
}
/* tablet */
@media only screen and (max-device-width: 1024px) {
    .forgot-password-header-container{
        display: block;
    }
    .forgot-password-image{
        display: none;
    }
    .force-change-password-screen .welcome-scr{
        min-height: 100% !important;
    }
    .banner-text{
        display: none;
    }
    .email-screen .welcome-logo-container {
        display: none !important;
      }
    
    .force-change-password-screen .welcome-scr .welcome-scr-banner{
        display: none !important;
    }
    .force-change-password-screen .welcome-user-container{
        max-height: calc(100vh - 80px) !important;
    }
    
    .reset-password-button-container {
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 18px 0px;
        margin-bottom: 26px;
    }
    .forgot-password-container{
        margin-bottom: 35px;
    }
    .forgot-password-screen-detail{
        margin-bottom: 10px;
    }
    .forgot-password-button-container{
        max-width: 100% !important;
        background-color: #fff !important;
    }
    .welcome-detail-container{

        margin: 0 auto;
        max-width:595px !important;
        display:flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 130px;
    }
    .force-change-password-screen .welcome-scr .welcome-scr-form{
        width: 100%;
    }
    .reset-password-button-wrapper{
        max-width:100%;
    }
}

@media only screen and (max-device-width: 767px) {
    .force-change-password-screen .Change-password-button-container{
        padding: 20px 12px !important;
        position: relative !important;
    }
    .forgot-password-container {
        text-align: left;
      }
    .forgot-password-screen-title{
        text-align: left;
    }
    .force-change-password-screen{
        background-size: 50%;
        
    }
    .reset-password-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        padding: 20px 20px !important;
    }
    .forgot-password-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
    }
    .forgot-password-input-wrapper{
        max-width: 100% !important;
    }
    .forgot-password-image{
        display: none;
    }
}

@media only screen and (max-device-width: 320px) {
    .reset-resend-button-container{
        margin-top: 20px;
    }
    .forgot-password-container {
        margin-top: 10px !important;
      }
}