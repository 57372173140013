.inline-cls {
    display: inline;
  }
  .inline-flex-cls {
    display        : flex !important;
    align-items    : center;
    justify-content: space-between;
    margin-top: 40px;
  }
  .m-t-48{
    margin-top: 48px;
  }
  .m-t-20{
    margin-top: 22px !important;
  }
  .m-t-2{
    margin: 2px;
  }
  .click-cursor{
    cursor: pointer;
  }
  .ml-10-mr-20{
    margin: 0 20px 0 10px;
  }

.inputError .span .span {
  display: flex;
  justify-content: center;
}

.input-icon div span span span span svg {
  vertical-align: initial !important;
}

.P-error
  div
  span.ant-input-affix-wrapper.ant-input-affix-wrapper-input-with-clear-btn {
  border-color: red !important ;
  box-shadow: none !important;
}
.DataTableClass .ant-modal-wrap {
  width: 400px !important;
  overflow-x: hidden !important;
}
.DataTableClass .statusModalClass .ant-modal-wrap {
  left: 68% !important;
}
.DataTableClass .statusModalClass .ant-modal-mask {
  width: 400px !important;
  left: 1212px !important;
}
.DataTableClass .ant-modal {
  width: 400px !important;
}
body {
  overflow: unset !important;
  width: 100% !important;
} 
.addEmployeeBtn-style .button-title-text {
  margin-top: 2px;
  margin-left: 5px;
}
