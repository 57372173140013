.pump-setup-wrapper {
  font-family: "Poppins", sans-serif;
  /* padding: 0px 20px 20px 20px; */
 
}
.pump-setup-body-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px 20px 20px;

}
.water-dest-body-wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 20px 20px;

}
.pump-setup-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width: 720px;
  position: relative;
}

.pump-setup-main-heading span {
font-size: 27px !important;
}

.pump-setup-content {
  font-weight: 700;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  color: #0b3471;
  margin-left: 18px;
}
.pump-setup-subContent{
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pump-setup-dashboard-navigate{
  /* font-family: "Poppins", sans-serif; */
  display: flex;
  margin-top: 10px;
  /* margin-left: px;  */
  margin-bottom: 10px;

}
.pump-setup-water-content {
  color: #006e96;
  font-weight: 700;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.pump-setup-bottom-content {
  font-weight: 600;
  font-size: 13px;
  margin-top: 20px;
}

.pump-setup-update-btn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: center;
  width: 100%;
  /* padding: 0px 20px; */
  position: absolute;
  /* bottom: 10px;
  left: 0; */
}

.pump-setup-update-btn div {
  display: flex;
  justify-content: center;
}

.pump-setup-update-btn div button {
  max-width: 520px;
  /* padding: 11px 0px; */
}
.pump-setup-modalImg{
  margin-right: 10px;
}

@media only screen and (max-width: 321px) {
  .myLabel {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: #FFFFFF;
    /* padding: 10px 15px; */
    border-radius: 100px;
    margin-right: 10px;
    width: 120px !important;
    height: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    font-size: 13px;
  }
  .myLabel .label-image img{
    height: 20px;
    width: 20px;
  }
 .myLabel .custom, .myLabel .label-image{
   width: 20%;
 }
 .myLabel .label-name {
  width: 60%;
 }
 }
@media only screen and (min-width: 360px) {
 .pump-setup-update-btn {
    bottom: 10px;
    left: 0;
  }

}
@media only screen and (min-width: 520px) {
  
  .pump-setup-update-btn {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
}

.myInput {
  appearance: none;
  position: relative;
  
}
.myLabel {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #FFFFFF;

  /* padding: 10px 15px; */
  border-radius: 100px;
  margin-right: 10px;
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
}

.myLabel.checkedItem {
  background: #F0F8FE!important;
}
.myLabel .custom {
  /* height: 10px;
  width: 10px; */
  visibility: hidden;
 
}
.myLabel .custom.checkedItem {
  /* border: 5px solid red;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%); */
  visibility: visible;
  
}
.right-tick-image{
  /* border: 5px solid red;
  background-color: red;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%); */

  /* position: absolute;
  left: 15px; */
}


.pump-system-btn-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
/* .leftTick{
  display: none;
}
.leftTick .visible{
display: block;
} */
.leftTick{
  display: block;
}
.myLabel .custom, .myLabel .label-image{
  width: 20%;
  display: flex;
  align-items: center;
}
.myLabel .label-name {
 width: 60%;
 display: flex;
 align-items: center;
}