.image {
    width: 335px;
    height: 242px;
    top: 369px;
    left: 22px;
    border-radius: 10px; 
    bottom:0; 
    display: block;
    width: 100%;
    height: auto;
  }
.uploadingText{
flex:3;
font-family: Poppins;
font-size: 13px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
text-align: center;
display: "flex";

}
.max-photos{
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 5px;
  color:#9A9A9A;
}
  .sub-notes{
    font-size: 13px;
  }
  .text-area{
    margin-right: 0px;
  }
  .sub-header{
    margin-top:30px;
  }
  .max-image{
    display:flex;
    margin-top:30px;
  }
  .description{
    margin-top:-40px;
    //margin-bottom:50px;

  }
  .imageContainer {
    position: relative;
    margin-top: 20px;
  }
  .deleteIcon {
    position: absolute;
    margin-top:10px;
    margin-right: 10px;
    top: 0;
    right: 0;
    background-color: #fff; /* Adjust the background color as needed */
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
  }
  .uploadButton{
    position: 'relative';
    min-height: '50vh'     
  }
  .continueStyle{
    position: 'absolute';
    bottom: '0px';
    left: '50%';
    right: 0;
    min-width: '335px';
    max-width: '860px';
    transform: 'translateX(-50%)';
  }
  .imageArea{
    padding-bottom: '10px';
  }
.imageUploadClass {
  font-family: Poppins;
  font-size: 18px;
  margin: 0px auto;
  width: 90%;
  padding-top: 5px;
}
.notesTitleWrapper {
  min-height: calc(100vh - 187px);
  font-family: Poppins;
  font-size: 18px;
  margin: 0px auto;
  width: 90%;
  margin-top: 20px;
}
.notesSubmitButton {
  position: relative;
  left: 0;
  right: 0;
  bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  margin-top: 20px;
}
.stepCountClass {
  margin-top: 20px;
  margin-left: 24px;
  font-size: 20px;
}
@media only screen and (min-width: 1024px) {
  .imageUploadClass {
    width: 50%;
    margin: 0px auto;
  }
  .imageViewLayout {
    margin: 23px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
  }
  .imageContainer {
    width: 100%;
  }
  .image {
    height: 250px !important;
  }
  .header-text {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 760px) {
  .notesTitleWrapper {
    min-height: 0px;
    margin: 0px auto;
    width: 50%;
    margin-top: 20px;
  }

  .notesSubmitButton {
    left: 0;
    right: 0;
    position: absolute;
    bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 35%;
    margin-top: 180px;
  }
}