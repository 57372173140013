.email-screen{
    background-image: url('../common_features/assets/triangle.png');
    background-repeat: no-repeat;
    min-height: 100vh;
    background-position: bottom right;
    /* background-size: 49%; */
    
}
.ErrorClass {
    color: #E00932;
}
.forceChangePassword {
    font-size: 15px;
}

.spinner-wrapper{
    justify-content: center;
    display: flex;
    align-items: center;
    position: fixed;
    height: 100vh;
    width: 100vw !important;
    left: 0;
    top: 0;
    background: rgba(255,255,255,0.5);
}

.change-password-screen-title{
    text-align: left;
    margin-top: 56px !important;
    margin-bottom: 16px !important;
}
.email-screen-detail{
    margin-bottom: 16px;
}
.change-password-input-wrapper{
    max-width: 590px !important;
    margin: 0 auto 0 !important;
}
.Change-password-button-container{
    max-width: 597px !important;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    /* box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.04); */
    padding: 40px 40px;
    /* background-color: #fff !important; */
}
.change-password-terms{
    display: flex;
    margin-bottom: 30px;
}
.change-password-validation{
    margin-bottom: 10px;
}
.change-password-button-wrapper{
    max-width:480px;
    margin: auto;
}
.change-password-confirm-input-wrapper{
    margin-bottom: 30px;
    position: relative;
}
.change-password-confirm-input-wrapper input{
    position: relative;
padding-right: 30px !important;
}
.change-password-confirm-input-wrapper.p2-error span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color:#E00932 !important ;
    box-shadow: none !important;

    }
.input-warningIcon2{
    position: absolute;
    top: 48px;
    right: 33px;
    z-index: 1;
}
.change-password-tips-wrapper{
    max-width: 478px;
    display: flex;
    margin-bottom: 35px;
}
.change-password-privacy-notice{
    max-width: 480;
}
.email-forgot-password-text{
    text-Align: right;
    margin-top:15px;
}
.enter-password-container{
    margin-bottom: 15px;
    position: relative;

}
.enter-password-container.P-error span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
border-color:#E00932 !important ;
box-shadow: none !important;

}
.enter-password-container input{
position: relative;
padding-right: 30px !important;
}
.input-warningIcon{
    position: absolute;
    top: 48px;
    right: 33px;
    z-index: 1;
}
@media (orientation: landscape)and (max-device-width: 1024px) {
    .email-screen-detail{
        margin-bottom: 10px;
        margin-left:90px;
    }
  }
/* tablet */
@media only screen and (max-device-width: 1024px) {
    .change-password-input-wrapper{
        margin: 10px auto 0 !important;
    }
    .email-screen-detail{
        margin-bottom: 10px;
    }
    .Change-password-button-container{
        max-width: 100% !important;
        background: transparent !important;
    }
}

@media only screen and (max-device-width: 767px) {
    .change-password-screen-title{
        text-align: left;
    }
    .email-screen{
        background-size: 50%;
        
    }
    .Change-password-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        position: relative;
    }
    .change-password-input-wrapper{
        max-width: 100% !important;
        margin: auto !important;
    }
}
@media only screen and (max-device-width: 375px) {
    .change-password-screen-title{
        margin-top: 0px !important;
    }
    .Change-password-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        position: relative;
    }
    
}
@media only screen and (max-device-width: 320px) {
    .change-password-screen-title{
        margin-top: 0px !important;
    }
}