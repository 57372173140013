.addEmployeeLoader {
    z-index: 9999;
    position: fixed !important;
    top: 78px;
    left: 252px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 252px);
    height: 100%;
    backdrop-filter: blur(1px);
    background: rgba(255,255,255,0.2);
}
.editViewUpperHeader{
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
    margin-top: 3px;
}
.EditButton {
    margin-top: 4px;
}
.EditButton .button-title-text{
    margin-top: 4px !important;
}

.disableFields .ant-input-affix-wrapper > input.ant-input {
    font-size: 16px !important;
    color : #9A9A9A !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    font-family: Poppins !important;
}

.enableFields .ant-input-affix-wrapper > input.ant-input {
    font-size: 16px !important;
    color : #333333 !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    font-family: Poppins !important;
}
.upperHeader {
    display: flex
}
.editBtnStyle {
    width: 190px;
    margin-top: -6px;
}
.headerWidth {
    width: 370px;
}
.footerBtn {
    width: 340px; 
    margin-left: 6px;
}
.footerBtn .button-title-text {
    margin-top: 4px !important;
}
.cancelBtn {
    width: 100px;
}
.enter-email-main-container {
   height: 100vh;
   overflow: unset !important;
}  
.store-assign-modal {
    position: fixed;
    display: flex;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.store-assign-modal-sub-div {
    position: absolute;
    width: 100%;
    z-index: -1;
    height: 100%;
    opacity: 0.8;
    background-color: #0B3471;
}
.selectStoreDiv {
    margin-bottom: 101px !important;
    margin-top: 14px;
    flex-direction: column;
}
.selectedStoreText {
    font-family: Poppins;
    font-weight: 400px;
    margin: 8px 0px 0px 10px;
    width: 340px;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
    word-wrap: break-word;
}
.store-assign-modal .table_tableContainer__3w2CQ {
    margin-bottom: 20px !important;
}
.store-assign-modal .table_tableContainer__3w2CQ table tbody td {
    padding: 12px !important;
    border-bottom: 0px !important;
}
.modal-with-table-addUser {
    width: 756px !important;
    height: 685px !important;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
}
.modal-Btn-div {
    margin-right: 12px;
    margin-top: 15px
}
.validationMessageStyle {
    color: #e00932;
    margin-left: 5px;
}
.store-assign-modal .filter_input_box {
    width: 676px !important;
}
.store-assign-modal .table_tableContainer__3IcUO {
    height: 320px !important;
}
.store-assign-modal .table-responsive {
    min-height: 318px !important;
    margin-bottom: 10px !important;
    overflow: hidden;
}
.store-assign-modal table tbody {
    display: block;
    max-height: 274px;
    overflow-y: auto;
}
.store-assign-modal table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.store-assign-modal .ant-input-affix-wrapper {
    width: 154% !important;
}
.store-assign-modal .filter_input_box {
    justify-content: unset !important;
}
.store-assign-modal .custom_filter_box {
    width: 170px !important;
}
.store-assign-modal table thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.store-assign-modal table thead {
    border-bottom-width: 2px;
}
.store-assign-modal table thead, .store-assign-modal table tbody tr {
    th:first-child {
        width: 23%;
    }
    td:first-child{ 
        width: 23%;
    }
    th:last-child {
        width: 12%;
    }
    td:last-child{ 
        width: 11%;
    }
}
.store-assign-modal table tbody tr {
    border-bottom: 0px;
}
.store-assign-modal .dropdown-menu {
    transform: translate(0px, 48px) !important;
}
.store-assign-modal tbody:before {
    line-height:1em;
    content:".";
    color:white;
    display:block;
}
.store-assign-modal .table_tableContainer__3IcUO table tbody {
    border: 0px !important;
}
.store-assign-modal table tbody tr:first-child {
    border-top: 0px !important;
}
.store-assign-modal .table>:not(caption)>*>* {
    border-bottom-width: 0px !important;
}
.store-assign-modal table tbody::-webkit-scrollbar {
    -webkit-appearance: none;
}
.store-assign-modal table tbody::-webkit-scrollbar:vertical {
    width: 7px;
}
.store-assign-modal table tbody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ebebebbb; 
    border-radius: 10px;
}
.store-assign-modal table tbody::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: #ebebeba9;
    background-color: rgba(0, 0, 0, .5);
}
.store-assign-modal .table_paginationStyle__3i3MG {
    margin: 12px -12px 7px 14px !important;
}