.alertContainer .left-content-container > img{
    width: 100px !important;   
}
// .alertContainer .left-content-container{
//     display: none !important;
//     width: 100px !important;
//     height: auto !important;
//     left: -10px !important;
// }
.alertContainer .tile-alert-container {
    flex: 1 !important;
    width: 100% !important;
    position: relative !important;
    left: 0px !important;
    margin-bottom: 10px !important;
}
.GoToHomeButton {
    margin-top: 280px;
    padding: 1rem;
}

@media only screen and (min-width: 720px) {
    .storeAlertDetailsTabWidth {
        max-width: 360px;
        min-height: auto;
        align-self: center;
    }
    .alertContainer {
        width: 100%;
    }
    .left-content-container {
        width: 130px !important;
        margin-right: 0px;
    }
}