.accessoryInst-body-wrapper{
  font-family: "Poppins", sans-serif;

}
.accessoryInst-body-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
}
.accessoryInst-body {
  min-height: calc(100vh - 187px);
  width: 100%;
  max-width: 720px;
  position: relative;
}
.accessoryInst-datePicker-Wrapper{
   
}
.email-icon2{
  position: absolute;
  top: 15px;
  right: 12px;
  z-index: 1;
}
.accessoryInst-datePicker{
    border-bottom: 1px solid #BFC1C4;
    padding: 15px 0px 20px 0px;
}

.addAccessory-heading-2 {
  margin-top: 10px;
}


.accessoryInst-btn {
    /* position: absolute; */
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    width: 100%;
    padding-bottom: 2.5rem;
    margin-top: 5px;
  }
  
  .accessoryInst-btn div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .accessoryInst-btn div button {
    max-width: 520px;
  }
.accessoryInst-datePicker-Wrapper{
  min-height: calc(100vh - 370px)
}
.PageCount {
  padding-left: 22px;
  padding-top: 15px;
  font-family: "poppins";
  font-size: 18px
}
@media only screen and (min-width: 380px) {
    .accessoryInst-btn {
      /* position: absolute; */
      /* bottom: 10px; */
      /* left: 0; */
    }
  }
  @media only screen and (min-width: 360px) {
    .accessoryInst-btn {
      /* position: absolute; */
      /* bottom: 10px; */
      /* left: 0; */
    }
  }
  @media only screen and (min-width: 320px) {
    .accessoryInst-btn {
      /* position: absolute; */
      /* left: 0; */
      /* bottom: 10px; */
    }
  }

  