
.otp-main-container .otp-header-container{
    display: none;
}
.white_logo{
    display: none !important;
}
.email-button{
    margin-Top:20;
    margin-bottom:20;
}

.email-update{
 margin-Top:140px !important;
 margin-bottom:20 !important;
}
.otp-screen-container .email-screen{
    background-image: url('../common_features/assets/triangle.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100% !important;
    display: flex;
    align-items: center;
}
.otp-screen-container .email-screen .row {
    display: flex;
    flex-direction: column;
    max-width: 430px;
    width: 100%;
    margin: 0 auto;
    min-height: calc(100vh - 80px) !important;
}
.otp-screen-container .email-screen .welcome-logo-container {
    max-width: 590px;
    margin:0px auto 0 !important;

}

.otp-input-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cust-otp-input-container>div{
    gap: 10;
}
.pentair-logo{
    display: flex;
}
.otp-screen-container .email-screen-title {
    text-align: left;
    max-width: 590px !important;
    margin: 40px auto 0 !important;

}
.otp-screen-container .email-screen-detail{
    margin-bottom: 52px;
}
.otp-screen-container .email-input-wrapper{
    max-width: 590px !important;
    margin: 16px auto 0 !important;
}
.otp-screen-container .email-button-container{
    max-width: 597px !important;
    margin: 0 auto;
    width: 100%;
}
.otp-screen-container .email-button-wrapper{
    max-width:335px ;
}
.otp-screen-container .email-forgot-password-text{
    text-Align: right;
    margin-top:15px;
}
.otp-screen-container .email-password-container{
    margin-bottom: 25px;
}
.forgot-password-container {
    position: relative;
}
.otp-screen-container .enter-password-container{
    margin-top: 34px !important;
    position: relative;
    margin-bottom: 0px !important;

}
.otp-screen-container .change-password-validation{
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}
.otp-screen-container .change-password-confirm-input-wrapper{
    margin-bottom: 30px !important;
    position: relative;
    margin-top: 20px !important;
}

.otp-screen-container .input-warningIcon {
    position: absolute !important;
    right: 33px !important;
    z-index: 1 !important;
    margin-right: 12px !important;
}
.otp-screen-container .input-warningIcon2 {
    position: absolute !important;
    right: 33px !important;
    z-index: 1 !important;
    margin-right: 12px !important;
}
.otp-screen-container .input-greenIcon
{
    height: 16px;
    width:16px;
    position: absolute;
    margin-top: 22px;
    right: 33px;
    z-index: 1;
    margin-right: 12px;
}
.otp-screen-container .enter-newpassword-container.P-error span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border-color:#E00932 !important ;
    box-shadow: none !important;
    margin-top:20px
    
    }
.change-password-confirm-input-wrapper.p2-error span.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
        border-color:#E00932 !important ;
        box-shadow: none !important;
 }

    .input-currentWarningIcon{
        height:16px;
        width: 16px;
        position: absolute;
        top: 42px;
        right: 12px;
        z-index: 1;
        margin-right: 20px;
    }

@media only screen and (min-device-width: 1024px) {
    .otp-screen-container .email-button-container{
        padding: 48px 0px !important;
    }
    .white_logo{
        width: 100%;
        height: "80px";
        margin-bottom: 15px;
        display: flex !important;
    }
    .otp-main-container .email-screen .row{
        display: flex;
        flex-direction: column;
    }
}
/* tablet */
@media only screen and (max-device-width: 1024px) {

    .otp-main-container .welcome-user-container{
        height: auto !important;
    }
    .otp-main-container .email-screen{
        min-height: calc(100vh - 80px) !important;
    }
   
    /* .otp-main-container .welcome-user-container{
        max-height: calc(100vh - 80px) !important;
    } */
    .otp-main-container .welcome-scr{
        min-height: auto !important;
    }

    .otp-main-container .otp-header-container{
        display: block;
    }
    .otp-main-container .welcome-logo-container{
        display: block;
    }
    .otp-main-container .email-screen-title{
        margin-top: 0px !important;
    }
    .otp-screen-container .email-input-wrapper{
        margin: 10px auto 0 !important;
    }
    .otp-screen-container .email-screen-detail{
        margin-bottom: 10px;
    }
    .otp-screen-container .email-button-container {
        /* position: absolute;
        bottom: 60px; */
        margin-bottom: 60px;
        left: 0;
        right: 0;
    }
    .otp-screen-container .welcome-image-container.welcome-scr-banner {
        display: none;
    }
    .otp-screen-container .email-screen {
        width: 100% !important;
        display: block;
        position: relative;
    }
    .otp-screen-container .email-button-wrapper {
        max-width: 100%;
    }
    .opt-input-container{
        display: flex;
    flex-direction: column;
    /* align-items: center; */
    }
    /* .otp-screen-container .email-button-container .email-button-wrapper span  {
        margin-top: 10px !important;
    } */
    .otp-screen-container .recieve-email {
        bottom: 0;
    }

    .recieve-email .email-button-wrapper  span{
        margin-top: 4px !important;
        margin-bottom: 0px !important;
    }
    .pentair-logo{
        display: none;
    }
}

@media only screen and (max-device-width: 767px) {
    .otp-screen-container .email-screen-title{
        text-align: left;
    }
    .otp-screen-container .email-screen{
        background-size: 50%;
        
    }
    
    .otp-screen-container .email-button-container{
        max-width: 100% !important;
        background-color: transparent !important;
        box-shadow: none;
        padding:0px 20px !important;
        margin-top: 15px !important;
    }
    .otp-screen-container .email-input-wrapper{
        max-width: 100% !important;
    }
    .otp-screen-container .email-screen .row {
        max-width: 100% !important;
        min-height: calc(100vh - 80px) !important;
    }

   
    .otp-screen-container .email-screen .row .welcome-logo-container {
        max-width: 100% !important;
     }
    .otp-screen-container .email-screen .row .email-screen-title {
       max-width: 100% !important;
    }
    .forgot-password-image{
        display: none;
    }
    .pentair-logo{
        display: none;
    }
    .otp-input-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media only screen and (max-device-width: 320px) {
    .otp-screen-container .email-screen-title{
        margin-top: 0px !important;
    }
    .otp-screen-container .email-button-container{
        padding:0px 20px !important;
    }
    .pentair-logo{
        display: none;
    }
    .otp-input-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}